// Buy now
export const BUY_NOW_INVITE_ID = 'i51253d'
export const BUY_NOW_QUERY_PARAM = 'invite'
export const BUY_NOW_COOKIE_NAME = 'cBuyNow'
export const BUY_NOW_PLANS_PAGE = '/plans/choose-plan'

// Preorder
export const PREORDER_COOKIE_NAME = 'cPreorder'
export const PREORDER_PRODUCT_IDENTIFIER = 'GB_TEST_KIT_PREORDER'
export const PREORDER_PLANS_PAGE = `/plans/choose-plan-58421`
export const PREORDER_CONFIRMATION_PAGE = '/checkout/preorder-confirmation'
export const PREORDER_COHORT_TYPE = 'preorder'

// Pricing experiment Oct 23
export const BRANCH_A_VARIANT = '11354' // default buy now
export const BRANCH_B_VARIANT = '21354' // starter/thrive/thrive plus
export type PricingExperimentOct23ParamType = typeof BRANCH_A_VARIANT | typeof BRANCH_B_VARIANT

// Constants used to determine which product config to use
export const GB_DEFAULT_PREORDER_PLANS_PAGE = '/choose-plan-58421'
export type PreorderPlansPath = typeof GB_DEFAULT_PREORDER_PLANS_PAGE

export type FulfilmentCohortType = typeof PREORDER_COHORT_TYPE
export const FULFILMENT_COHORT_COOKIE_NAME = 'cFulCohort'

export const QUIZ_COMPLETE_COOKIE_NAME = 'cQuizComplete'

export const QUIZ_ID_COOKIE_NAME = 'cUserId'
export const QUIZ_EMAIL_NAME = 'cUserEmail'

// Shipping
export const TEST_KIT_SHIPPING_TIME_GB = '3-5 working days'
export const TEST_KIT_SHIPPING_TIME_US = '7-9 days'
